import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/src/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/AppBar/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Footer/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Provider","defaultTheme","View","Flex"] */ "/app/src/libs/spectrum.ts");
;
import(/* webpackMode: "eager" */ "/app/src/styles/reset.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/colors.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/colors-light.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/colors-dark.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.scss");
;
import(/* webpackMode: "eager" */ "/app/src/styles/utils.scss");
